import React, { useState } from "react";

import { AppBar, Toolbar, IconButton, Typography, useTheme, Container, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import { Box, Menu, Avatar, Tooltip, MenuItem } from "@mui/material";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectCurrentLanguage, updateCurrentLanguage } from "../homeSlice";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE } from "../../common/MetaSetting";

export const Header = () => {
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(DEFAULT_LANGUAGE);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLanguageChange = (event: React.MouseEvent<HTMLElement>, newAlignment: any) => {
    setCurrentLang(newAlignment);
    dispatch(updateCurrentLanguage(newAlignment));
    i18n.changeLanguage(newAlignment === "zhCN" ? "zh-CN" : "en-US");
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#efefef", color: theme.palette.primary.dark }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <Avatar sx={{ bgcolor: '#efefef', display: { xs: 'none', md: 'flex' }, mr: 1 }} variant="square" alt="Wise Memo" src="/logo.svg" />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {t('titleWiseMemo')}
          </Typography> */}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              color: "#8E8E8E",
              padding: "5px",
              borderRadius: "5px",
              bgcolor: "background.paper",
            }}
          >
            <Avatar variant="square" alt="Wise Memo" src="/logo.svg" />
            {currentLang === DEFAULT_LANGUAGE ? (
              <Box>
                <span style={{ color: theme.palette.primary.main }}>极智</span>笔记
              </Box>
            ) : (
              <Box>
                <span style={{ color: theme.palette.primary.main }}>W</span>ise<span style={{ color: theme.palette.primary.main }}>M</span>emo
              </Box>
            )}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            {/* <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {[t('menuAbout')].map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
          </Box>
          <Avatar sx={{ bgcolor: "#efefef", display: { xs: "flex", md: "none" }, mr: 1 }} variant="square" alt="Wise Memo" src="/logo.svg" />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {t("titleWiseMemo")}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {/* {[t('menuAbout')].map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: theme.palette.primary.dark, display: 'block' }}
              >
                {page}
              </Button>
            ))} */}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <ToggleButtonGroup value={currentLanguage} exclusive onChange={handleLanguageChange} size="small" color="primary">
              <ToggleButton value="zhCN">{t("langZh")}</ToggleButton>
              <ToggleButton value="enUS">{t("langEn")}</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
