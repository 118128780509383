import React from "react";

import { ReactNode } from "react";
import { Toolbar, Box, styled } from "@mui/material";

import { Header } from "./Header";
import { Main } from "./Main";
import { Footer } from "./Footer";
import { useTranslation } from "react-i18next";

const OuterContainer = styled(Box)`
  display: flex;
  overflow: hidden;
  height: inherit;
  flex-direction: column;
  min-height: 100vh;
`;

const InnerContainer = styled(Box)`
  display: flex;
  flex: 1;
  overflow: hidden;
  height: inherit;
`;

interface ILayoutProps {
  children: NonNullable<ReactNode>;
}

export const Layout = ({ children }: ILayoutProps) => {
  const { t } = useTranslation();

  // const getVerifyInfo = () => {
  //   return (
  //     <div>
  //       {t("copyright")}
  //       <div style="width: 300px; margin: 0 auto; padding: 20px 0">
  //         <a
  //           target="_blank"
  //           href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010402001486"
  //           style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px"
  //         >
  //           <img src="" style="float: left" />
  //           <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: #939393">川公网安备 51010402001486号</p>
  //         </a>
  //       </div>
  //       <a href="https://beian.miit.gov.cn/" target="_blank">
  //         {t("verifyInfo")}
  //       </a>
  //     </div>
  //   );
  // };

  return (
    <OuterContainer>
      <Header />
      {/* <Toolbar /> */}
      <InnerContainer>
        <Main>{children}</Main>
      </InnerContainer>
      <Footer>
        <div>
          {t("copyright")}
          {" | "}
          <a href="https://beian.miit.gov.cn/" target="_blank">
            {t("verifyInfo")}
          </a>
          {" | "}
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010402001486">
            <img src="/verifyLogo.png" /> 川公网安备 51010402001486号
          </a>
        </div>
      </Footer>
    </OuterContainer>
  );
};
