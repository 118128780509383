import React from "react";

import { PropsWithChildren } from "react";
import { styled } from "@mui/material";

const StyledMain = styled("main")`
  width: 100%;
  height: 60%;
  overflow: none;
  padding: 5px;
`;

export const Main = ({ children }: PropsWithChildren<unknown>) => <StyledMain>{children}</StyledMain>;
