import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

import { WEB_VERIFY_ICP_STRING } from "./common/MetaSetting";

const container = document.getElementById("root")!;
const root = createRoot(container);

const verifyContainer = document.getElementById("verify")!;

import Home from "./home/Home";

import "./assets/i18n";

root.render(
  <React.Fragment>
    <CssBaseline />
    <BrowserRouter>
      <Provider store={store}>
        <Home />
      </Provider>
    </BrowserRouter>
  </React.Fragment>
);


verifyContainer.innerHTML = `<a href="https://beian.miit.gov.cn/" target="_blank" hidden>${WEB_VERIFY_ICP_STRING}</a>`;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
