import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../app/store";

import { DEFAULT_LANGUAGE } from "../common/MetaSetting";

import * as locales from "@mui/material/locale";
type SupportedLocales = keyof typeof locales;

export interface HomeState {
  currentLanguage: SupportedLocales;
}

const initialState: HomeState = {
  currentLanguage: DEFAULT_LANGUAGE,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    updateCurrentLanguage: (state, action: PayloadAction<SupportedLocales>) => {
      state.currentLanguage = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { updateCurrentLanguage } = homeSlice.actions;

export const selectCurrentLanguage = (state: RootState) => state.home.currentLanguage;

export default homeSlice.reducer;
