import React from "react";

import { PropsWithChildren } from "react";
import { Box, useTheme } from "@mui/material";

export const Footer = ({ children }: PropsWithChildren<unknown>) => {
  const theme = useTheme();

  if (!children) {
    return null;
  }

  return (
    <Box
      sx={{
        padding: "0.4em",
        background: "#efefef",
        color: theme.palette.primary.main,
        textAlign: "center",
        fontSize: "0.8em",
      }}
    >
      {children}
    </Box>
  );
};
