import React, { useEffect, useState } from "react";

import { Box, Button } from "@mui/material";
import { WISE_MEMO_WEB_ADDRESS } from "../../common/MetaSetting";
import { useTranslation } from "react-i18next";

export const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {}, []);

  return (
    <Box
      sx={{
        padding: "1em",
      }}
    >
      <h1>{t("welcomeString")}</h1>
      <p>{t("welcomeDescription")}</p>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 0,
        }}
      >
        <Button href={WISE_MEMO_WEB_ADDRESS} variant="contained" sx={{ bgcolor: "#fb4e2b" }}>
          {t("buttonStartToUser")}
        </Button>
      </Box>
    </Box>
  );
};

export default Dashboard;
