import React, { useRef, useEffect, useMemo } from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import { Layout } from "./shared";

import { useAppSelector } from "../app/hooks";
import { Dashboard } from "./pages";

import { ROUTES } from "./constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { selectCurrentLanguage } from "./homeSlice";

import * as locales from "@mui/material/locale";
import { defaultTheme } from "../common/MetaSetting";
import { useTranslation } from "react-i18next";

const titleContainer = document.getElementById("title")!;

export const Home = () => {
  const { t } = useTranslation();
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const themeWithLocale = useMemo(() => createTheme(defaultTheme, locales[currentLanguage]), [currentLanguage, defaultTheme]);

  const ref = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    ref.current = setInterval(() => {}, 60000);

    return () => {};
  }, []);

  titleContainer.innerHTML = t("webTitle");

  return (
    <ThemeProvider theme={themeWithLocale}>
      <Layout>
        <Routes>
          <Route path={ROUTES.root} element={<Dashboard />} />
        </Routes>
      </Layout>
    </ThemeProvider>
  );
};

export default Home;
