export const WEB_VERIFY_ICP_STRING = "蜀ICP备14023292号-4";

export const WISE_MEMO_WEB_ADDRESS = "https://web.wisememo.com";

export const DEFAULT_LANGUAGE = "zhCN";

import { createTheme } from "@mui/material/styles";
export let defaultTheme = createTheme({
  palette: {
    primary: {
      light: "#FEF0EF",
      main: "#FB4E2B",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#F5F5F5",
      main: "#8E8E8E",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});
